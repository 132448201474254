import { useBreakpoint } from "~/contexts/breakpoint";

import styles from "./tabbed-product-carousel-background.module.scss";

type Props = {
  color1: string;
  color2: string;
};

export default function TabbedProductCarouselBackground({ color1, color2 }: Props) {
  const breakpoint = useBreakpoint();

  return (
    <div className={styles.background}>
      {breakpoint === "mobile" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="375"
          height="551"
          viewBox="0 0 375 551"
          fill="none"
          className={styles.backgroundSvg}
        >
          <g opacity="0.18" filter="url(#filter0_f_5983_16951)">
            <path
              d="M-61.4264 367.501C-116.729 417.52 -207.503 366.76 -244.037 304.994C-288.739 229.418 -235.021 178.903 -182.822 180.586C-76.9194 184.002 -24.4702 67.4233 83.2656 47.2455C368.731 -6.219 354.222 200.082 292.621 246.723C123.786 374.558 81.1713 207.188 19.5769 232.171C-33.226 253.589 -6.12387 317.482 -61.4264 367.501Z"
              fill={color1}
            />
          </g>
          <g opacity="0.18" filter="url(#filter1_f_5983_16951)">
            <path
              d="M114.149 242.801C190.907 240.457 219.493 166.173 200.692 114.361C177.689 50.9654 101.715 52.2195 65.111 80.3542C-9.1509 137.434 -100.014 74.4951 -192.902 120.023C-439.025 240.659 -225.641 345.167 -146.794 337.746C69.3118 317.405 -86.4271 238.72 -23.4624 219.82C30.5151 203.617 37.3911 245.145 114.149 242.801Z"
              fill={color2}
            />
          </g>
          <defs>
            <filter
              id="filter0_f_5983_16951"
              x="-312.075"
              y="-12.9058"
              width="692.632"
              height="452.067"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="25.6863" result="effect1_foregroundBlur_5983_16951" />
            </filter>
            <filter
              id="filter1_f_5983_16951"
              x="-356.856"
              y="13.0048"
              width="612.038"
              height="374.016"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="24.4507" result="effect1_foregroundBlur_5983_16951" />
            </filter>
          </defs>
        </svg>
      )}
      {breakpoint === "desktop" && (
        <svg
          width="946"
          height="575"
          viewBox="0 0 946 575"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.backgroundSvg}
        >
          <g opacity="0.8">
            <g opacity="0.18" filter="url(#filter0_f_5983_34558)">
              <path
                d="M455.388 166.221C383.757 152.346 333.459 240.811 334.148 311.026C334.99 396.938 405.545 412.031 448.398 384.189C535.336 327.703 598.985 427.942 699.405 390.281C965.487 290.491 802.08 110.313 726.887 102.455C520.795 80.9181 639.282 215.495 575.06 225.767C520.005 234.574 527.019 180.096 455.388 166.221Z"
                fill={color1}
              />
            </g>
            <g opacity="0.18" filter="url(#filter1_f_5983_34558)">
              <path
                d="M258.419 417.284C193.006 449.134 121.729 376.907 104.271 309.1C82.9095 226.133 146.972 193.387 195.433 209.144C293.748 241.113 329.184 128.138 435.627 138.496C717.667 165.94 606.777 381.658 536.38 408.632C343.434 482.566 422.827 322.363 358.318 329.044C303.017 334.771 323.832 385.433 258.419 417.284Z"
                fill={color2}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_f_5983_34558"
              x="234.141"
              y="0.125"
              width="711.637"
              height="498.859"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_5983_34558" />
            </filter>
            <filter
              id="filter1_f_5983_34558"
              x="0.0413818"
              y="37.8223"
              width="718.153"
              height="490.348"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_5983_34558" />
            </filter>
          </defs>
        </svg>
      )}
    </div>
  );
}
